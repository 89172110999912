import { graphql, useStaticQuery } from 'gatsby'

export enum PRICING_DEAL_PLAN_NAMES_TYPE {
  access_7_day = 'ACCESS_7_DAY',
  monthly = 'MONTHLY',
  best_value = 'BEST_VALUE'
}

export interface PricingDealPlanNames {
  type: PRICING_DEAL_PLAN_NAMES_TYPE
  text: string
}

export interface CommonsTextProps {
  lawGeniusTitle: string
  lawGeniusIcon: {
    url: string
  }
  currrencySymbol: string
  currencyCode: string
  pricingDealPlanNames: PricingDealPlanNames[]
}

export const useQueryCommonsText = (): CommonsTextProps => {
  const data: { strapiCommonsText: CommonsTextProps } = useStaticQuery(graphql`
    query {
      strapiCommonsText {
        lawGeniusTitle
        lawGeniusIcon {
          url
        }
        currrencySymbol
        currencyCode
        pricingDealPlanNames {
          type
          text
        }
      }
    }
  `)

  return data.strapiCommonsText
}
