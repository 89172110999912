const FORMAT: Record<string, string> = {
  AU: 'dd/MM/yyyy',
  CA: 'yyyy/MM/dd',
  US: 'MM/dd/yyyy',
  GB: 'dd/MM/yyyy'
}

interface FormatByCountry {
  [key: string]: {
    locale: string
    format: DateTimeFormatOptions
  }
}

export const FORMAT_BY_COUNTRY: FormatByCountry = {
  AU: {
    locale: 'en-AU',
    format: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }
  },
  CA: {
    locale: 'en-CA',
    format: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }
  },
  US: {
    locale: 'en-US',
    format: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }
  },
  GB: {
    locale: 'en-GB',
    format: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }
  }
}

export const DATE_FORMAT_BY_COUNTRY = FORMAT[process.env.COUNTRY ?? 'US']
export const DATE_FORMAT_BY_COUNTRY_OBJECT = FORMAT_BY_COUNTRY[process.env.COUNTRY ?? 'US']
