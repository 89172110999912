import React from 'react'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { Loading, PreviewAndPricing } from '../../molecules'
import { type PricingComponentOldFunnelProps } from './PricingComponentOldFunnelProps'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { createCustomerSubscriptionUseCase } from '@legal/subscription'
import { getHtmlPreviewByUuidUseCase } from '@legal/application'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useBenefitsListToShow } from '@legal/shared/hooks/useBenefitsListToshow'

export const PricingComponentOldFunnel: React.FC<PricingComponentOldFunnelProps> = ({
  location,
  version,
  subscription,
  loadingSubscription,
  subscriptionsType
}): React.ReactElement => {
  const { benefitsListToShow } = useBenefitsListToShow({ subscription })
  const ratafiaFreeBenefits = benefitsListToShow?.filter((benefit) => {
    return benefit?.type !== 'lawgenius'
  })
  const { applicationCookie, setApplicationCookieId, setApplicationCookieFormName } = useApplicationCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const referrerCookie = new ReferrerCookie()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [htmlPreview, setHtmlPreview] = React.useState<string>('')
  const [isLoadingDataToPaymentPage, setIsLoadingDataToPaymentPage] = React.useState<boolean>()
  const [message, updateMessage] = React.useState({
    type: '',
    message: ''
  })

  if (location.state) {
    if (location.state.applicationId) setApplicationCookieId(location.state.applicationId)
    if (location.state.subscriptionId) subscriptionCookie.subscriptionId = location.state.subscriptionId

    if (location.state.formName) setApplicationCookieFormName(location.state.formName)
  }

  React.useEffect(() => {
    const getHtmlPreview = async (): Promise<void> => {
      if (!applicationCookie?.id) return
      await getHtmlPreviewByUuidUseCase({
        applicationId: applicationCookie.id,
        successCallback: setHtmlPreview,
        finallyCallback: () => {
          setLoading(false)
        }
      })
    }
    getHtmlPreview()
  }, [])

  const getUrlParams = (subscriptionSelectedIdParam): string | undefined => {
    const formProduct = getParamUrlValue('product')
    const formType = getParamUrlValue('type')
    let urlParametersTracking = ''

    urlParametersTracking = formProduct ? urlParametersTracking + `product=${formProduct}` : urlParametersTracking + ''

    urlParametersTracking = formType ? urlParametersTracking + `&type=${formType}` : urlParametersTracking + ''
    if (subscriptionSelectedIdParam && subscriptionsType.length > 0) {
      const selectedSubscription = subscriptionsType.find(
        (subscriptionItem) => subscriptionItem.id === subscriptionSelectedIdParam
      )

      const selectedSubscriptionPlanName = stringSlugify(selectedSubscription?.type?.name)

      return urlParametersTracking + `&subs-type=${selectedSubscriptionPlanName}`
    }
  }

  const createCustomerSubscriptionSuccessCallback = (subscriptionId: string): void => {
    subscriptionCookie.subscriptionId = subscriptionId
    setIsLoadingDataToPaymentPage(true)
    const tempmessage = { ...message }
    tempmessage.type = 'success'
    tempmessage.message = 'Redirecting to the payment page...'
    updateMessage(tempmessage)
    const urlParametersTracking = `/payment?${getUrlParams(subscription.id)}`

    navigate(urlParametersTracking, {
      state: {
        applicationId: applicationCookie?.id,
        subscriptionId: subscriptionCookie.subscriptionId,
        subscriptionTypeId: subscription.id,
        formName: applicationCookie?.form?.name
      }
    })
  }

  const createCustomerSubscriptionErrorCallback = (error): void => {
    const tempmessage = { ...message }
    tempmessage.type = 'error'
    tempmessage.message = error?.request?.response?.message ?? 'there was an error with your request'
    updateMessage(tempmessage)
  }

  const submitForm = async (): Promise<void> => {
    const data = {
      subscriptionPriceId: subscription.id,
      applicationId: applicationCookie?.id
    }
    subscriptionCookie.subscriptionTypeId = data.subscriptionPriceId
    const nameOfSubscriptionSelected = subscriptionsType.filter((type) => {
      return type.id === data.subscriptionPriceId
    })
    const initialDocumentType = applicationCookie?.form?.name
    const eventProps = {
      pricing_plan: nameOfSubscriptionSelected[0]?.type.name ?? null,
      initial_document_type: stringSlugify(initialDocumentType) ?? null,
      source: referrerCookie.getReferrer(),
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('select_pricing', eventProps)
    if (!applicationCookie?.id) return
    await createCustomerSubscriptionUseCase({
      request: {
        applicationId: applicationCookie.id,
        subscriptionPriceId: subscription.id
      },
      successCallback: createCustomerSubscriptionSuccessCallback,
      errorCallback: createCustomerSubscriptionErrorCallback
    })
  }

  if (loading && loadingSubscription) return <Loading />

  return (
    <PreviewAndPricing
      title={`Your ${applicationCookie?.form?.name} is ready to download!`}
      template={<div dangerouslySetInnerHTML={{ __html: htmlPreview }} />}
      formType={'formType'}
      formSubType={'formSubType'}
      draftActive
      benefitsList={ratafiaFreeBenefits}
      handleContinue={submitForm}
      loading={loading && loadingSubscription}
      subscription={subscription}
      version={version}
      loadingDataToPaymentPage={isLoadingDataToPaymentPage}
      newPricingPage
    />
  )
}
