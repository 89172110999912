import { NavBarDropDown } from '@npm_leadtech/legal-lib-components/NavBarDropDown'
import React from 'react'
import ReactDOMServer from 'react-dom/server'

import type { MenuDesktopItem, MenuMobileItem, ProgressBarProps } from './ProgressBarProps'
import { FormContext } from '../../../services/utils/contexts'
import chevronExpandLess from '../../../assets/images/svg/expand_less_24px.svg'
import chevronExpandMore from '../../../assets/images/svg/expand_more_24px.svg'
import { parseLabel } from 'src/services/form/parse'
import { updateApplicationDataUseCase } from '@legal/application'
import { useApplicationCookie } from '@legal/shared/hooks'
import './ProgressBar.scss'

export const ProgressBar: React.FC<ProgressBarProps> = ({
  allSteps,
  currentStep,
  customGoToStep,
  documentTitle,
  formData,
  sections,
  sectionsActivated
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [enableScrollbarOnClose, setEnableScrollbarOnClose] = React.useState<() => void>()
  const { applicationCookie } = useApplicationCookie()
  const currentStepData = allSteps[currentStep - 1]
  const currentStepSlug = currentStepData.slug
  const iterableObject =
    sectionsActivated ?
      sections.map((section: string) => {
        const stepIndex = allSteps.findIndex((step) => step.sectionNav === section)
        return {
          label: section,
          active: currentStepData.sectionNav === section,
          onClick: () => {
            changeStep(stepIndex + 1)
          }
        }
      })
    : allSteps.map((step, index: number) => {
        return {
          label: step.label,
          active: currentStepSlug === step.slug,
          onClick: () => {
            changeStep(index)
          }
        }
      })
  const currentItem = iterableObject.findIndex((item: MenuDesktopItem) => item.active)
  let currentProgress = (100 * currentItem) / iterableObject.length
  switch (currentItem) {
    case 0:
      currentProgress = 0
      break
    case iterableObject.length - 1:
      currentProgress = 100
      break
    default:
      currentProgress = currentProgress + 50 / iterableObject.length
  }
  const componentStyle = {
    width: `${currentProgress}%`
  }

  const formContext = React.useContext(FormContext)

  const getCurrentStepData = (): Record<string, string> => {
    const data: Record<string, string> = {}

    currentStepData.groups.forEach((group) => {
      group.fields.forEach((field) => {
        const fieldName = formData[field].name
        const fieldValue = formData[field].value
        data[fieldName] = fieldValue
      })
    })

    return data
  }

  const getStepDataAndUpdate = async (): Promise<void> => {
    if (currentStepSlug === 'print-and-download' || !applicationCookie?.id) return
    await updateApplicationDataUseCase({
      applicationId: applicationCookie.id,
      request: {
        data: getCurrentStepData(),
        step: currentStepSlug,
        stepNumber: currentStep,

        html: ReactDOMServer.renderToStaticMarkup(formContext.template)
      },
      successCallback: () => {
        applicationCookie.lastStep = currentStep
      }
    })
  }

  const changeStep = (selectedStep: number): void => {
    getStepDataAndUpdate()
    customGoToStep.goToStep(selectedStep)
  }

  const itemList = iterableObject.map((item: MenuDesktopItem, index: number) => {
    let itemLabel = item.label
    if (item.label && formContext.form) {
      itemLabel = parseLabel({ label: item.label, form: formContext.form })
    }
    return (
      <li
        role='presentation'
        key={`section-item-${index}`}
        data-qa={'changeStep'}
        className={`section-item ${item.active ? 'active' : ''}`}
        onClick={item.onClick}
      >
        {itemLabel}
      </li>
    )
  })

  const menuMobileList = iterableObject.map(
    ({ label, onClick }: MenuDesktopItem): MenuMobileItem => ({
      content: parseLabel({ label, form: formContext.form }) || label,
      onClick: () => {
        onClick()
        setIsOpen(false)
        if (enableScrollbarOnClose !== undefined) {
          enableScrollbarOnClose()
        }
      }
    })
  )

  const closeMenuMobile = (): void => {
    setIsOpen(false)
    if (enableScrollbarOnClose !== undefined) {
      enableScrollbarOnClose()
    }
  }

  const openMenuMobile = (): void => {
    setIsOpen(true)
  }

  return (
    <div>
      <div className={'progress-bar'}>
        <div className={'progress-bar__product-name serif --extra-large'}>{documentTitle}</div>
        <ul className={'progress-bar__section-names'}>{itemList}</ul>
        <div
          role='presentation'
          className={'progress-bar__section-names__mobile'}
          onClick={isOpen ? closeMenuMobile : openMenuMobile}
        >
          {sections[currentItem]}
          {isOpen ?
            <img src={chevronExpandLess} width='24' height='24' alt='open menu mobile' />
          : <img src={chevronExpandMore} width='24' height='24' alt='close menu mobile' />}
        </div>
        <div className={'progress-bar__bar'}>
          <div id='current-progress' className={'current-progress'} style={componentStyle}></div>
        </div>
      </div>
      <NavBarDropDown
        setEnableScrollbarOnClose={setEnableScrollbarOnClose}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        list={menuMobileList}
        initialActiveContent={sections[currentItem]}
      />
    </div>
  )
}
