import { type Subscription } from '@legal/subscription'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useAllSiteMetadata } from './useAllSiteMetadata'
import { useConfigs } from '@legal/core/configs'
import { useQuerySharedComponentsFromPaymentPage } from '../data/graphql'

export interface BenefitBySubscriptionProps {
  benefit: string
  type: 'default' | 'lawgenius'
}

export const useBenefitsListToShow = ({
  subscription
}: {
  subscription: Subscription | undefined
}): { benefitsListToShow: BenefitBySubscriptionProps[] | undefined } => {
  const { IS_JONSNOW, RATAFIA_CONFIG } = useConfigs()
  const { SITE_PHONE } = useAllSiteMetadata()
  const { subscriptions } = useQuerySharedComponentsFromPaymentPage()
  const subscriptionType = subscriptions?.find(
    (benefitList) => benefitList?.sku === subscription?.type.name || benefitList?.name === subscription?.type.name
  )
  if (!subscriptionType?.benefitsBySubscriptions?.length) return { benefitsListToShow: undefined }

  const benefitsListToShow = subscriptionType.benefitsBySubscriptions
    .filter((benefitBySubscription) => RATAFIA_CONFIG.ENABLED || benefitBySubscription?.type === 'default')
    .map((benefitBySubscription) => ({
      ...benefitBySubscription,
      benefit: replaceValues(benefitBySubscription?.benefit, { SITE_PHONE })
    })) as BenefitBySubscriptionProps[] | undefined

  if (IS_JONSNOW && subscription?.isSevenDayAccess) {
    benefitsListToShow?.pop()
  }

  return {
    benefitsListToShow
  }
}
