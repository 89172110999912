/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { Button } from '@npm_leadtech/legal-lib-components/Button'

import { useConfigs } from '@legal/core/configs'

import { useApplicationCookie } from '@legal/shared/hooks'
import { useQueryDocumentActionsPage } from '@legal/shared/data/graphql'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import {
  type Application,
  downloadApplicationPdfUseCase,
  findApplicationByUuidUseCase,
  getHtmlPreviewByUuidUseCase,
  updateApplicationNameUseCase
} from '@legal/application'
import { DocumentActionsHeader, LoadingPdfGenerationDocumentActions, NewDocumentModal } from '../../molecules'
import Download from '../../../assets/images/componentsSvg/icon-24-px-download.svg'
import Edit from '../../../assets/images/componentsSvg/icon-24-px-edit.svg'
import { Layout } from '../../layout'
import NewDoc from '../../../assets/images/componentsSvg/icon-24-px-newdoc.svg'
import Print from '../../../assets/images/componentsSvg/icon-24-px-print.svg'
import { TemplateContainer } from '../TemplateContainer'
import { TextInput } from '../../form'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { downloadPdfHelper } from '../../../services/utils/downloadPdfHelper'
import { isSupportedDevice } from '../../../services/utils/isSupportedDevice'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useIsMobile } from '@legal/shared/hooks'
import { useModal } from '../../../services/hooks/useModal'
import './DocumentActionsPageTemplate.scss'

const keyNamePrintButton = 'actions-item-print'
const keyNameDownloadButton = 'actions-item-download'
const keyNameNewDocButton = 'actions-item-new'
const dataQaBackToEdit = 'back-to-edit'
const dataQaFinish = 'finish'

export const DocumentActionsPageTemplate: React.FC = () => {
  const { TARGET_ADDRESS } = useConfigs()
  const {
    applicationCookie,
    setApplicationCookieFormName,
    setApplicationCookieLastStep,
    setApplicationCookieFromEdit,
    setApplicationCookieFormDriveId
  } = useApplicationCookie()

  const { backToEditBtnLabel, downloadBtnLabel, finishBtnLabel, newBtnLabel, printBtnLabel } =
    useQueryDocumentActionsPage()
  const [applicationId] = React.useState<string | undefined>(applicationCookie?.id)
  const [originalFormName] = React.useState(applicationCookie?.form?.name ?? '')
  const [application, setApplication] = React.useState<Application>()
  const [htmlPreview, setHtmlPreview] = React.useState<string>('')
  const [newDocuments, showNewDocuments] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [base64Pdf, setBase64Pdf] = React.useState<string>()
  const [isDisabled, setIsDisabled] = React.useState<boolean>()
  const [editingFormName, setEditingFormName] = React.useState(false)
  const [formName, setFormName] = React.useState<string>(applicationCookie?.form?.name ?? '')
  const [reference, setReference] = React.useState<string>()
  const userCookie = new UserCookie()
  const userToken = userCookie ? userCookie.token : ''
  const documentType = originalFormName.toLowerCase().split(' ').splice(1, 3).join('-')
  const modalNewDocument = useModal()
  const isMobile = useIsMobile(0)

  React.useEffect(() => {
    const getHtmlPreview = async (): Promise<void> => {
      if (!userToken || !applicationId) navigate('/my-account/my-documents/')
      await getHtmlPreviewByUuidUseCase({
        applicationId,
        successCallback: setHtmlPreview
      })
    }
    getHtmlPreview()
  }, [userToken, applicationId])

  const handleChangeStatus = async (value: string): Promise<void> => {
    if (value === 'error') setIsDisabled(true)
    if ((value === 'success' || value === 'pdf_generated' || value === 'downloaded') && applicationId) {
      await findApplicationByUuidUseCase({
        applicationId,
        successCallback: (application) => {
          setApplication(application)
          setFormName(application.name)
          setReference(application.reference)
        }
      })
      await downloadApplicationPdfUseCase({
        applicationId,
        successCallback: (pdfEncoded) => {
          if (!pdfEncoded) return
          setBase64Pdf(pdfEncoded.content)
        }
      })
    }
    setLoading(false)
  }

  // edit name
  const onEditApplicationName = (applicationId: string, name: string): void => {
    if ((applicationCookie?.id ?? applicationCookie?.idToDownload) === applicationId) {
      setApplicationCookieFormName(name)
    }
  }

  const editNameClicked = (): void => {
    const action = 'rename'
    setEditingFormName(true)

    setTimeout(() => {
      // ToDo investigar por qué no aplica el focus con el ref
      const inputElement = document.querySelector(
        isMobile ? '.document-actions__document .is-mobile input' : '.document-actions__titleAndIcon input'
      )
      inputElement?.focus()
    }, 0)

    const eventProps = {
      action_type: action,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('actions', eventProps)
  }

  useEffect(() => {
    // Código repetido por iOS trick
    if (!editingFormName) return
    const inputElement = document.querySelector(
      isMobile ? '.document-actions__document .is-mobile input' : '.document-actions__titleAndIcon input'
    )
    inputElement?.focus()
  }, [editingFormName])

  const onChangeFormName = React.useCallback((e: any): void => {
    if (e.target.value.length < 40) {
      setFormName(e.target.value)
    }
  }, [])

  const onBlurFormName = React.useCallback(async (e: any): Promise<void> => {
    if (!applicationId) return
    await updateApplicationNameUseCase({
      applicationId,
      name: e.target.value,
      successCallback: () => {
        onEditApplicationName(applicationId, e.target.value)
      },
      errorCallback: (error) => {
        setFormName(originalFormName)
        console.log('error', error)
      },
      finallyCallback: () => {
        setEditingFormName(false)
      }
    })
  }, [])

  // button actions functions
  const handleDownload = (): void => {
    const tab = null
    const action = 'download'
    downloadPdfHelper({ base64Pdf, applicationName: formName, applicationReference: reference, tab, action })

    const eventProps = {
      action_type: action,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('actions', eventProps)
  }

  const printDocument = (): void => {
    const isAndroid = isSupportedDevice([/Android/i])
    const tab = !isAndroid ? window.open('', '_blank') : null
    const action = 'print'
    downloadPdfHelper({
      base64Pdf,
      applicationName: formName,
      applicationReference: reference,
      tab,
      action,
      isAndroidDevice: isAndroid
    })

    const eventProps = {
      action_type: action,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('actions', eventProps)
  }

  const toggleShowNewDocumentMenu = (): void => {
    showNewDocuments(!newDocuments)
    modalNewDocument.changeModalState()
  }

  const closeNewDocumentMenu = (): void => {
    showNewDocuments(false)
    modalNewDocument.changeModalState()
  }

  const documentActionsHeader = {
    buttons: [
      <a
        className={`actions-header__item ${isDisabled ? '--disabled' : ''}`}
        data-qa={keyNamePrintButton}
        key={keyNamePrintButton}
        onClick={printDocument}
      >
        <Print />
        <label className={`actions-header__label ${isDisabled ? '--disabled' : ''}`}>{printBtnLabel}</label>
      </a>,
      <a
        download={formName}
        className={`actions-header__item ${isDisabled ? '--disabled' : ''}`}
        data-qa={keyNameDownloadButton}
        key={keyNameDownloadButton}
        onClick={handleDownload}
      >
        <Download />
        <label className={`actions-header__label ${isDisabled ? '--disabled' : ''}`}>{downloadBtnLabel}</label>
      </a>,
      <a
        className={'actions-header__item'}
        data-qa={keyNameNewDocButton}
        key={keyNameNewDocButton}
        onClick={toggleShowNewDocumentMenu}
      >
        <NewDoc />
        <label className='actions-header__label last-label'>{newBtnLabel}</label>
      </a>
    ],
    title: (
      <>
        {editingFormName && (
          <TextInput
            type={'text'}
            placeholder={''}
            givenClass='document-actions__title --editing is-mobile'
            value={formName}
            required={false}
            isValidGroup={true}
            maxlength={100}
            validate={true}
            onBlur={onBlurFormName}
            onChange={onChangeFormName}
          />
        )}
        {!editingFormName && <h2 className='document-actions__title is-mobile'>{formName}</h2>}
        {!editingFormName && (
          <>
            <div className='document-actions__edit is-mobile' onClick={editNameClicked}>
              <Edit />
            </div>
          </>
        )}
      </>
    )
  }

  // back to edit
  const backToEdit = (applicationId, position): void => {
    window.localStorage.setItem('isEditing', 'editing')
    setApplicationCookieLastStep(undefined)
    setApplicationCookieFromEdit(true)
    setApplicationCookieFormDriveId(undefined)

    const eventProps = {
      source: position,
      application_ID: applicationId,
      document_type: stringSlugify(application?.form.type) ?? null,
      document_subtype: stringSlugify(application?.state) ?? stringSlugify(application?.form.subType) ?? null,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('edit_document', eventProps)

    if (!application?.form.url) return
    navigate(application.form.url)
  }

  if (loading) return <LoadingPdfGenerationDocumentActions handleChangeStatus={handleChangeStatus} />

  return (
    <div>
      <Layout
        headerBackground={'transparent'}
        headerHasNoProducts={true}
        environment={TARGET_ADDRESS}
        disclaimerLinks={true}
        noFooter={true}
        footerContactBar={true}
        isTestAB8814
        mobileHideResources={true}
        desktopNavbarPhone
      >
        <div className='document-actions'>
          <div className='document-actions__container'>
            <div className='document-actions__items'>
              <a
                className={`document-actions__item-test8814 ${isDisabled ? '--disabled' : ''}`}
                data-qa={keyNamePrintButton}
                key={keyNamePrintButton}
                onClick={printDocument}
              >
                <Print />
                <label className={`document-actions__label ${isDisabled ? '--disabled' : ''}`}>{printBtnLabel}</label>
              </a>
              <a
                download={formName}
                className={`document-actions__item-test8814 ${isDisabled ? '--disabled' : ''}`}
                data-qa={keyNameDownloadButton}
                key={keyNameDownloadButton}
                onClick={handleDownload}
              >
                <Download />
                <label className={`document-actions__label ${isDisabled ? '--disabled' : ''}`}>
                  {downloadBtnLabel}
                </label>
              </a>
              <a
                className={'document-actions__item-test8814'}
                data-qa={keyNameNewDocButton}
                key={keyNameNewDocButton}
                onClick={toggleShowNewDocumentMenu}
              >
                <NewDoc />
                <label className='document-actions__label last-label'>{newBtnLabel}</label>
              </a>
            </div>
            <div className='document-actions__titleAndIcon'>
              {editingFormName && (
                <TextInput
                  type={'text'}
                  placeholder={''}
                  givenClass='document-actions__title --editing not-mobile'
                  value={formName}
                  required={false}
                  isValidGroup={true}
                  maxlength={100}
                  validate={true}
                  onBlur={onBlurFormName}
                  onChange={onChangeFormName}
                />
              )}
              {!editingFormName && <h2 className='document-actions__title not-mobile'>{formName}</h2>}
              <div className='document-actions__edit not-mobile' onClick={editNameClicked}>
                <Edit />
              </div>
            </div>
            <div className='document-actions__document'>
              <DocumentActionsHeader {...documentActionsHeader} />
              {newDocuments && <NewDocumentModal closeFunction={closeNewDocumentMenu} />}
              <TemplateContainer
                template={<div dangerouslySetInnerHTML={{ __html: htmlPreview }} />}
                hideHeader
                documentType={documentType}
                categoryProduct={application?.state.toLowerCase() ?? application?.form.subType ?? ''}
                draftActive={false}
                minHeight={true}
                borderRadius={true}
              />
            </div>
            <div className='document-actions__buttons'>
              <Button
                givenClass='large-button grey-bg'
                color='secondary'
                label={backToEditBtnLabel ?? ''}
                dataQa={dataQaBackToEdit}
                noLink
                onClick={() => {
                  backToEdit(applicationId, 'actions')
                }}
              />
              <Button
                givenClass='large-button'
                dataQa={dataQaFinish}
                label={finishBtnLabel ?? ''}
                link={'/my-account/my-documents'}
                LinkComponent={Link}
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
