import { DATE_FORMAT_BY_COUNTRY_OBJECT } from '@legal/shared/data'
import { logError } from '@legal/shared/logger'

interface Datepicker {
  class: string
  component: string
  disabled: boolean
  errorMessage: string
  id: string
  isStartDate: boolean
  isValidGroup: boolean
  label: string
  name: string
  placeholder: string
  relatedDateFormName: null | string
  required: boolean
  tooltip: string
  type: string
  validate: boolean
  value: string
}

export const getValueDatepicker = (itemForm: Datepicker, dateFormatCountry = DATE_FORMAT_BY_COUNTRY_OBJECT) => {
  const formatter = new Intl.DateTimeFormat(dateFormatCountry.locale, dateFormatCountry.format)
  if (!itemForm.value) return ''
  let formattedDate = itemForm.value
  try {
    const newDate = new Date(itemForm.value)
    formattedDate = formatter.format(newDate)
    return formattedDate
  } catch (error) {
    logError('error getValueDatepicker' + error)
    return itemForm.value
  }
}
