/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { GetApplicationsRequest, GetApplicationsResponse } from '../../application'
import { ApplicationPaymentStatus } from '../../domain'
import { ApplicationsData } from '../dataObjects'
import { ApplicationsDataToApplication } from '../adapters'
import { ENDPOINTS } from './Endpoints'
import { PaymentCookie } from '../../../../services/storage/cookies/PaymentCookie'
import { get } from '@legal/shared/HttpClient'

export async function getApplications({
  limit,
  offset,
  orderBy,
  orderDir
}: GetApplicationsRequest): Promise<GetApplicationsResponse> {
  const url = `${ENDPOINTS.GET_APPLICATIONS}?limit=${limit}&offset=${offset}&orderBy=${orderBy}&orderDir=${orderDir}`
  const result = await get<ApplicationsData>(url, true)
  const applications = ApplicationsDataToApplication(result)
  const paymentCookie = new PaymentCookie()
  const hasApplicationPaying = paymentCookie.paymentStatus === ApplicationPaymentStatus.PAYING
  const hasApplicationPay =
    paymentCookie.paymentStatus !== ApplicationPaymentStatus.PAYING &&
    applications.some((application) => application.actions?.pay)
  return {
    applications,
    hasApplicationPaying,
    hasApplicationPay
  }
}
