import { graphql, useStaticQuery } from 'gatsby'

import { useConfigs } from '@legal/core/configs'

export const useQuerySharedComponentsFromPaymentPage = (): Queries.STRAPI_PAYMENT_PAGE => {
  const { STRAPI_CONFIG } = useConfigs()
  const data = useStaticQuery(graphql`
    query {
      allStrapiPaymentPage(filter: { title: { ne: "mock" } }) {
        edges {
          node {
            slowPaymentProcessing {
              title
              text
            }
            currency
            currencySymbol
            moreAccessText
            lessAccessText
            subscriptions {
              name
              sku
              description
              timeDuration
              shortBillingText
              longBillingText
              benefitsBySubscriptions {
                type
                benefit
              }
              youCanList {
                benefit
              }
            }
            forgotPassword {
              title
              feedBackError {
                btnLabel
                title
                text
              }
              feedBackSuccess {
                btnLabel
                title
                text
              }
              emailInput {
                label
                placeholder
                errorMessages {
                  text
                }
              }
            }
            forgotPasswordModal {
              title
              footerText
              footerCta
            }
            registerModal {
              title
              footerText
              footerCta
              alternativeTitle
            }
            loginModal {
              title
              footerText
              footerCta
              alternativeTitle
            }
            validator {
              canotBeUsedOnSiteName
              cardCannotBeUsed
              checkCorrectEmail
              checkDetailsAndTryAgain
              correctEmail
              enterEmail
              incorrectFormatEmail
              specialCharactersEmail
            }
            signIn {
              signInCta
              forgotPasswordCta
              divider
              feedBackError {
                btnLabel
                title
                text
              }
              emailInput {
                label
                placeholder
                errorMessages {
                  text
                }
              }
              passwordInput {
                label
                placeholder
                errorMessages {
                  text
                }
              }
            }
            signUp {
              divider
              googleLabel
              createAccountCta
              noThanksCta
              feedBackError {
                btnLabel
                title
                text
              }
              feedBackSuccess {
                title
                text
              }
              toastSuccess {
                text
                title
              }
              emailInput {
                label
                placeholder
                errorMessages {
                  text
                }
              }
              passwordInput {
                label
                placeholder
                errorMessages {
                  text
                }
              }
              alternativeFeedBackSucces {
                btnLabel
                text
                title
                alternativeTitle
                subtext
              }
              termsAndConditions {
                data {
                  childMarkdownRemark {
                    htmlAst
                  }
                }
              }
            }
            bestOptionBadge {
              content {
                data {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              icon {
                url
              }
            }
            domain {
              name
            }
          }
        }
      }
    }
  `)
  return data.allStrapiPaymentPage.edges.find(
    ({ node }: { node: Queries.STRAPI_PAYMENT_PAGE }) => node.domain?.name === STRAPI_CONFIG.DOMAIN
  ).node as Queries.STRAPI_PAYMENT_PAGE
}
