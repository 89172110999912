/* eslint-disable react-compiler/react-compiler */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import classNames from 'classnames'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { DialogMenuIcon, ProductFaqs, TemplateFooter, TemplateHeader } from '../../molecules'
import { parseLabel, parseLabels } from '../../../services/form/parse'
import { DATE_FORMAT_BY_COUNTRY } from '@legal/shared/data'
import FormComponents from '../FormComponents'
import { FormContext } from '../../../services/utils/contexts'
import IconContent from '../../../assets/images/componentsSvg/icon-24-px-content.svg'
import { type MainFormStepsProps } from './MainFormStepsProps'
import { PreformTypeFormCookie } from '../../../services/storage/cookies/PreformTypeFormCookie'
import { TemplateContainer } from '../../template'
import { arrayCompareEqual } from '../../../services/utils/arrayCompare'
import error from '../../../assets/images/svg/info-error_24px_outlined.svg'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import { referenceHandler } from '../../../services/form/referenceHandler'
import { replaceValues } from '../../../services/utils/replaceAll'
import { updateApplicationDataUseCase } from '@legal/application'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useQueryFormPage } from '@legal/shared/data/graphql'
import './MainFormSteps.scss'

export const MainFormSteps: React.FC<MainFormStepsProps> = ({
  currentStep,
  disabledNextStep = false,
  formStateName,
  formSubType,
  formType,
  goToStep,
  handleStepActive,
  hashKey,
  indexItem,
  isHashInUrlFirstTime,
  nextStep,
  previousStep,
  structure,
  totalSteps,
  template,
  recoveredLastStep,
  changeRecoveredLastStep = false,
  handleChangeRecoveredLastStep,
  showPreviewLastStep,
  setIsLastStepActive,
  setFaqs,
  faqs,
  isTestAB8814 = false
}) => {
  const {
    applicationCookie,
    setApplicationCookieFormName,
    setApplicationCookieFormDriveId,
    setApplicationCookieLastStep,
    setApplicationCookieFormUrl
  } = useApplicationCookie()
  const { multiStepFormWizard, priceContent, productsFaqs } = useQueryFormPage()
  const formContext = React.useContext(FormContext)
  const [form] = React.useState(formContext.form)
  const [stepKey] = React.useState(currentStep - 1)
  const [loadingStep, setLoadingStep] = React.useState(true)

  const getTemplateHeaderContent = (): {
    title: React.JSX.Element
    subtitle: string
    buttons: React.JSX.Element
  } => {
    return {
      title: (
        <>
          <span className={'icon-title'}>
            <IconContent />
          </span>
          {replaceValues(multiStepFormWizard?.headerTitleTemplate, { FORM_NAME: formContext.formName })}
        </>
      ),
      subtitle: multiStepFormWizard?.headerSubtitleTemplate,
      buttons: (
        <Button
          label={priceContent?.item ?? ''}
          noLink
          onClick={() => {
            changeWindowsLocation()
          }}
          dataQa={'completeApplication'}
        />
      )
    }
  }

  const preFormNextStep = (): void => {
    if (!window.location.hash) {
      goToStep(2)
    }
  }

  React.useEffect(() => {
    const preformTypeFormCookie = new PreformTypeFormCookie()
    let urlProductActive = getParamUrlValue('product') ?? ''
    urlProductActive = urlProductActive.trim()
    const preformTypeFormData = preformTypeFormCookie.readItem(urlProductActive)

    formContext.updateCurrentStepAndStructure(currentStep, structure)
    if (isHashInUrlFirstTime()) {
      if (preformTypeFormData && !preformTypeFormData.firstStepSkipped) {
        preformTypeFormData.firstStepSkipped = true
        preformTypeFormCookie.newItem(urlProductActive, preformTypeFormData)
      }
      preFormNextStep()
      formContext.customGoToStep(goToStep)
    } else {
      if (preformTypeFormData && !preformTypeFormData.firstStepSkipped) {
        preformTypeFormData.firstStepSkipped = true
        preformTypeFormCookie.newItem(urlProductActive, preformTypeFormData)
        preFormNextStep()
      } else {
        formContext.customGoToStep(goToStep)
      }
    }
    setApplicationCookieFormName(formContext.formName)
    handleStepActive?.(indexItem)
    setLoadingStep(false)
  }, [])

  React.useEffect(() => {
    if (recoveredLastStep && !changeRecoveredLastStep) {
      goToStep(recoveredLastStep)

      handleChangeRecoveredLastStep(true)
    }
  }, [recoveredLastStep])

  const nextStepForm = (): void => {
    if (!disabledNextStep) {
      nextStepHandler()
      if (formContext.stepValidator(stepKey) && formContext.isValidGroup(stepKey)) {
        nextStep()
        window.scrollTo(0, 0)
        const eventProps = {
          document_type: formType,
          document_subtype: formSubType || formStateName || '',
          application_ID: applicationCookie?.id,
          section_name: hashKey,
          version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
        }
        sendAmplitudeData('complete_section', eventProps)
      }
    }

    if (hashKey === 'state') {
      const eventPropsState = {
        document_subtype: formSubType || formStateName || '',
        application_ID: applicationCookie?.id
      }
      sendAmplitudeData('start_state', eventPropsState)
    }
  }

  const nextStepHandler = async (stepData = step): Promise<void> => {
    setApplicationCookieLastStep(currentStep)
    if (applicationCookie?.form?.driveId !== formContext.driveId) {
      setApplicationCookieFormDriveId(formContext.driveId)
    }
    if (!applicationCookie?.id) return
    await updateApplicationDataUseCase({
      request: {
        data: getDataFromStep(stepData),
        step: hashKey,
        stepNumber: currentStep,
        html: ReactDOMServer.renderToStaticMarkup(formContext.template)
      },
      applicationId: applicationCookie.id,
      successCallback: () => {
        setApplicationCookieFormUrl(formContext.formUrl)
      }
    })
  }

  const getDataFromStep = (stepData = step): any => {
    let data = {}

    stepData.groups.map((group) => {
      group.fields.map((field) => {
        const fieldName = form[field].name
        const fieldValue = form[field].value
        data[fieldName] = fieldValue
      })
    })
    if (stepData.groups.length === 0) {
      data = {
        isTestAB8814: true
      }
    }
    return data
  }

  const previousStepForm = (): void => {
    previousStep()
    window.scrollTo(0, 0)
  }

  const changeWindowsLocation = React.useCallback(async (): Promise<void> => {
    const htmlObject = ReactDOMServer.renderToStaticMarkup(formContext.template)
    const eventProps = {
      document_type: formType,
      document_subtype: formSubType || formStateName || '',
      application_ID: applicationCookie?.id,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }

    sendAmplitudeData('complete_application', eventProps)
    const lastStepData = {
      step: structure.steps[stepKey],
      data: getDataFromStep()
    }

    formContext.submitForm(lastStepData)
    await updateApplicationDataUseCase({
      applicationId: applicationCookie?.id ?? '',
      request: {
        step: lastStepData.step.slug,
        lastStep: true,
        data: lastStepData.data,
        html: htmlObject
      },
      successCallback: () => {
        navigate('/document-actions')
      }
    })
  }, [
    getDataFromStep,
    structure.steps,
    stepKey,
    formContext,
    formType,
    formSubType,
    formStateName,
    applicationCookie?.id
  ])

  const renderButtons = (): React.JSX.Element => {
    return (
      <section className={'buttons-wrapper'}>
        {currentStep > 1 && (
          <div className={'button button__back'}>
            <Button
              color='secondary'
              label={multiStepFormWizard?.btnBackLabel ?? ''}
              noLink
              onClick={previousStepForm}
              functionParameters={''}
              dataQa={'back'}
            />
          </div>
        )}
        {currentStep < totalSteps && (
          <>
            <div className={'button button__next'}>
              <Button
                label={multiStepFormWizard?.btnSaveAndContinueLabel ?? ''}
                noLink
                onClick={nextStepForm}
                functionParameters={''}
                dataQa={'saveAndContinue'}
              />
            </div>
            <div className={'button button__skip'}>
              <Button
                color='styleless'
                label={multiStepFormWizard?.btnSkipStepLabel ?? ''}
                noLink
                onClick={nextStepForm}
                dataQa={'skipStep'}
              />
            </div>
          </>
        )}
        {currentStep === totalSteps && (
          <div className={'button button__complete'}>
            <Button
              label={multiStepFormWizard?.btnCompleteAplicationLabel ?? ''}
              noLink
              onClick={changeWindowsLocation}
              dataQa={'completeApplication'}
              functionParameters={''}
            />
          </div>
        )}
      </section>
    )
  }

  const getOtherProps = (field, handleChange): any => {
    const otherProps = {}
    switch (field.component) {
      case 'RemoveButton':
        otherProps.onChange = () => {
          const reversEvent = {
            target: {
              value: 'no',
              name: field.items.removeButton.reference
            }
          }
          handleChange(reversEvent)
        }
        break
      case 'DatePicker':
        if (field.relatedDateFormName && form[field.relatedDateFormName]?.value) {
          otherProps.relatedDate = new Date(form[field.relatedDateFormName].value)
        }
        otherProps.dateFormat = DATE_FORMAT_BY_COUNTRY
        break
      case 'Radio':
        if (field.items) {
          otherProps.items = parseLabels({ labels: field.items, form })
        }
        break
      case 'SearchSelect':
        if (field.value && typeof field.value === 'object') {
          field.value.label = parseLabel({ label: field.value.label, form })
        } else if (field.value) {
          field.value = parseLabel({ label: field.value, form })
        }
        field.items = parseLabels({ labels: field.items, form })
        break
    }
    if (field.placeholder) {
      otherProps.placeholder = parseLabel({ label: field.placeholder, form })
    }
    if (field.tooltip) {
      otherProps.tooltip = parseLabel({ label: field.tooltip, form })
    }

    otherProps.label = parseLabel({ label: field.label, form })
    return otherProps
  }

  const renderGroup = (group, handleChange, validation): React.JSX.Element | undefined => {
    let once = false

    const fieldInGroupHasError = group.fields.map((item) => {
      if (!form[item].validate && form[item].required) {
        form[item].validate = false
        return (
          <p className={'form-group__errorMessage sans-serif --small'}>
            <img src={error} alt={form[item].errorMessage} />
            {form[item].errorMessage}
          </p>
        )
      }
      if (!form[item].isValidGroup && !once) {
        once = true
        return (
          <p className={'form-group__errorMessage sans-serif --small'}>
            <img src={error} alt={form[item].errorMessage} />
            {form[item].errorMessage}
          </p>
        )
      }
    })

    let fieldLabel = group.label
    fieldLabel = parseLabel({ label: fieldLabel, form })

    if (referenceHandler(group, form, structure)) {
      let tooltipString = group.tooltip?.replace(/<[^>]*>?/gm, '')
      tooltipString = parseLabel({ label: tooltipString, form })

      return (
        <div key={group.name} className={`form-group ${group.refField ? '--isReferenced' : ''}`}>
          {fieldLabel && (
            <div className='form-group__label__container'>
              <h4 className={'form-group__label sans-serif --medium dialog-menu-label'}>{fieldLabel}</h4>
              {tooltipString && (
                <DialogMenuIcon
                  dialogMenuTip={tooltipString}
                  title={fieldLabel}
                  onOpenFunc={() => {
                    formContext.setTooltipModalContent({ title: fieldLabel, text: tooltipString })
                  }}
                  onCloseFunc={() => {
                    formContext.closeTooltipModal()
                  }}
                  currentStep={hashKey}
                  formType={formType}
                  formSubtype={formSubType}
                  isTestAB8814={isTestAB8814}
                  formContext={formContext}
                ></DialogMenuIcon>
              )}
            </div>
          )}
          {formContext.toggleGroupNames && <h3>{group.name}</h3>}
          {group.fields.map((item) => {
            const field = form[item]
            const FieldComponent = FormComponents[field.component]
            const otherProps = getOtherProps(field, formContext.handleChange)
            return (
              <FieldComponent
                key={field.id}
                onChange={formContext.handleChange}
                onBlur={(e) => validation(e.target.name)}
                form={form}
                {...field}
                {...otherProps}
              />
            )
          })}
          {fieldInGroupHasError}
        </div>
      )
    }
  }

  const renderStep = (step): React.JSX.Element | null => {
    let fieldLabel = step.label
    const Faqs = []
    fieldLabel = parseLabel({ label: fieldLabel, form })

    step?.groups?.forEach((group) => {
      if (referenceHandler(group, form, structure) && group?.faq?.length) {
        Faqs.push(group)
      }
    })

    if (setFaqs) {
      if (!arrayCompareEqual(faqs, Faqs)) {
        setFaqs(Faqs)
      }
    }
    if (setIsLastStepActive) {
      setIsLastStepActive(currentStep === totalSteps)
    }

    const classFormAndFaqs = classNames({
      'form-and-faqs': true,
      '--istestAB8814': isTestAB8814,
      '--last-step': currentStep === totalSteps
    })

    if (loadingStep) {
      return null
    }

    return (
      <section className={classFormAndFaqs}>
        <div className='form-step'>
          {showPreviewLastStep ?
            <>
              <TemplateHeader {...getTemplateHeaderContent()} />
              <div className={'preview-title-wrapper'}>
                <h1 className={'preview-title'}>{formContext.formName}</h1>
              </div>
              <div className='template-container-wrapper'>
                <TemplateContainer
                  template={template}
                  hideHeader
                  documentType={formType}
                  categoryProduct={formSubType}
                  draftActive={true}
                  version={formContext.version}
                />
              </div>
              <TemplateFooter>
                <Button
                  label={priceContent?.item ?? ''}
                  noLink
                  onClick={() => {
                    changeWindowsLocation()
                  }}
                  dataQa={'completeApplicationBottomTemplate'}
                />
              </TemplateFooter>
            </>
          : <>
              <h2 className={'form-step__title sans-serif --large'}>{fieldLabel}</h2>
              {step.groups.map((group) => {
                return renderGroup(group, formContext.handleChange, formContext.validation)
              })}
              {renderButtons()}
            </>
          }
        </div>
        {!isTestAB8814 && (
          <div className={'step-faq'}>
            {Faqs.map((group, i) => {
              const groupFaqTransformed = group.faq.map((faq) => {
                const titleFaq = parseLabel({ label: faq.title, form })
                const titleContent = parseLabel({ label: faq.content, form })
                return { title: titleFaq, content: titleContent }
              })
              return (
                <div className={'step-faq-group'} key={`group-${i}`}>
                  {!i && <h3 className={'step-faq__title sans-serif --medium'}>{productsFaqs?.item}</h3>}
                  <ProductFaqs key={group.name} faqsArray={groupFaqTransformed} />
                </div>
              )
            })}
          </div>
        )}
      </section>
    )
  }

  const step = structure.steps[stepKey]

  return <>{renderStep(step)}</>
}
