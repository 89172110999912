/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { Toggle } from '@npm_leadtech/legal-lib-components/Toggle'
import classNames from 'classnames'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { type Application, findApplicationByUuidUseCase } from '@legal/application'
import { FormContext, OverlayContext } from '../../../../services/utils/contexts'
import { FormState, getFormStates, getFormsByTypeUseCase } from '@legal/form'
import { Layout, Modal } from '../../../layout'
import { Loading, ProgressBar, TooltipModal } from '../../../molecules'
import {
  addValuesToTemporalForm,
  getLastStepBeforeResumingApplication,
  getSectionList,
  updateCurrentStepAndStructure,
  validator
} from '../utilities'
import { createPreFormDataToSend, setPrefillParameterInForm } from '../../../../services/utils/preForm/preForm'
import { useActions, useHashFirsTime } from '../hooks'
import { useApplicationCookie, useIsMobile, useSubscriptionToShowCustomer } from '@legal/shared/hooks'
import { type FormTemplateMessiProps } from './FormTemplateMessiProps'
import { FormToTemplate } from '../../../../services/form/FormToTemplate'
import { LoginModal } from '../../LoginModal'
import { MultiStepFormMessi } from '../../../form'
import { PreformTypeFormCookie } from '../../../../services/storage/cookies/PreformTypeFormCookie'
import { ReferrerCookie } from '../../../../services/storage/cookies/ReferrerCookie'
import { RegisterModal } from '../../RegisterModal'
import { TemplateContainer } from '../../TemplateContainer'
import { UserCookie } from '../../../../services/storage/cookies/UserCookie'
import { addStepsTypeAndState } from '../../../../services/utils/stepStateMessiBuilder/stepStateMessiBuilder'
import { cloneObjectOrArray } from '../../../../services/utils/cloneObjectOrArray/cloneObjectOrArray'
import { getNoRenderedFields } from '../../../../services/form/getNoRenderedFields'
import { getParamUrlValue } from '../../../../services/utils/paramsUrl'
import { initState } from '../constants'
import { newStepStructure } from '../../../../services/form/newStepStructure'
import { normalizeFormJson } from '../../../../services/form/normalizeFormJson'
import { replaceAll } from '../../../../services/utils/replaceAll'
import { stringSlugify } from '../../../../services/utils/stringSlugify'
import { useBenefitsListToShow } from '@legal/shared/hooks/useBenefitsListToshow'
import { useBilling } from '../../../../services/hooks/useBilling'
import { useConfigs } from '@legal/core/configs'
import { useModal } from '../../../../services/hooks/useModal'
import './FormTemplateMessi.scss'

const reducer = (prevState, updatedProperty): any => ({
  ...prevState,
  ...updatedProperty
})

export const FormTemplateMessi: React.FC<FormTemplateMessiProps> = ({
  data,
  uri,
  positionOfDynamicStates = 2,
  title,
  version
}) => {
  const { IS_LOCAL_ENV, IS_STAGEPRODUCT_ENV, TARGET_ADDRESS, COUNTRY } = useConfigs()
  const [customGoToStepObject, setCustomGoToStep] = React.useState(null)
  const [recoveredLastStep, setRecoveredLastStep] = React.useState(null)
  const itemChangedKey = React.useRef(1)
  const oldNoRenderFields = React.useRef()
  const [showOverlay, setOverlayState] = React.useState(false)
  const [showPreviewSidebar, updateShowPreviewSidebar] = React.useState(false)
  const [disabledPreviewScroll, setDisabledPreviewScroll] = React.useState(false)
  const templateModal = useModal(true)
  const loginModal = useModal(true)
  const registerModal = useModal(true)
  const isMobile = useIsMobile(0)
  const tooltipModal = useModal(isMobile)
  const referrerCookie = new ReferrerCookie()
  const [loading, isLoaded] = React.useState(true)
  const [loadForm, setLoadForm] = React.useState(false)
  const [tooltipModalContent, setTooltipModalContent] = React.useState(null)
  const [formStates, setFormStates] = React.useState<FormState[]>()
  const [bottomOverlayContent, setBottomOverlayContent] = React.useState(null)

  const callbackBottomOverlay = (overlayContent): void => {
    setBottomOverlayContent(overlayContent)
  }
  const [preFormDataToSendApplication, setPreFormDataToSendApplication] = React.useState(null)
  const showPreviewLastStep = true
  const formData: Queries.StrapiFormDataFragment = data.strapiForm
  const formJson = normalizeFormJson(formData, showPreviewLastStep)
  const tempNoRenderBuffer = getNoRenderedFields(formJson.plainJson, formJson.structureJson)
  const initialTemplate = FormToTemplate(
    formData.form_template,
    formJson.plainJson,
    tempNoRenderBuffer,
    '',
    false,
    // eslint-disable-next-line react-compiler/react-compiler
    itemChangedKey
  )
  const [state, updateState] = React.useReducer(reducer, { ...initState, template: initialTemplate })
  const { form, originalForm, structure, template, noRenderFields, toggleGroupNames, loadedData } = state
  const stepsData = newStepStructure(form, structure, originalForm, [], true)
  const { groupValidator, stepValidator, fieldValidator } = validator({ stepsData, updateState, form })
  const { subscription, setSubscription, loadingSubscription, isSevenDayAccess, subscriptionsType } =
    useSubscriptionToShowCustomer()
  const { hasSubscriptionActive } = useBilling()
  const { isHashInUrlFirstTime } = useHashFirsTime()
  const [productName] = React.useState(formData.formType?.typeId ?? '')
  const { applicationCookie, setApplicationCookieFormDriveId, clearApplicationCookie } = useApplicationCookie()
  const userCookie = new UserCookie()

  const stepsCount = stepsData.newStepStructure.steps.length
  const isLastStep = state.currentStep === stepsCount

  const { benefitsListToShow } = useBenefitsListToShow({ subscription })
  const ratafiaFreeBenefits = benefitsListToShow?.filter((benefit) => {
    return benefit?.type !== 'lawgenius'
  })

  React.useEffect(() => {
    async function fetchData(): Promise<void> {
      if (applicationCookie?.continueFromHome) {
        setApplicationCookieFormDriveId(formData.driveId ?? undefined)
      }

      await getFormsByTypeUseCase({
        request: { type: btoa(productName), country: COUNTRY },
        successCallback: (forms) => {
          setFormStates(getFormStates(forms))
        }
      })

      if (version === 'messi') {
        const eventProps = {
          document_type: formType,
          document_subtype: formSubType,
          application_ID: applicationCookie?.id,
          source: referrerCookie.getReferrer(),
          version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
        }
        sendAmplitudeData('start_form', eventProps)
      }
    }
    fetchData()
  }, [version])

  React.useEffect(() => {
    const formToRender = Object.keys(form).length === 0 && form.constructor === Object ? formJson.plainJson : form
    const tempNoRender = getNoRenderedFields(formToRender, formJson.structureJson)
    updateState({
      template: FormToTemplate(formData.form_template, formToRender, tempNoRender, '', toggleGroupNames, itemChangedKey)
    })
  }, [toggleGroupNames])

  React.useEffect(() => {
    if (formStates) {
      const preformTypeFormCookie = new PreformTypeFormCookie()
      let urlProductActive = getParamUrlValue('product') ?? ''
      urlProductActive = urlProductActive.trim()
      const preformTypeFormData = preformTypeFormCookie.readItem(urlProductActive)
      let newFormJson = cloneObjectOrArray(formJson)
      if (formStates.length > 0) {
        newFormJson = addStepsTypeAndState({
          formJson: newFormJson,
          formStates,
          positionOfDynamicStates: positionOfDynamicStates - 1
        })
      }
      newFormJson = setPrefillParameterInForm(newFormJson, preformTypeFormData)

      if (!preformTypeFormData?.firstStepSkipped) {
        setPreFormDataToSendApplication(createPreFormDataToSend(newFormJson))
      }

      const originalForm = normalizeFormJson(formData).plainJson

      const tempNoRender = getNoRenderedFields(newFormJson.plainJson, newFormJson.structureJson)
      oldNoRenderFields.current = tempNoRender
      const parsedTemplate = FormToTemplate(
        formData.form_template,
        newFormJson.plainJson,
        tempNoRender,
        '',
        toggleGroupNames,
        itemChangedKey
      )
      updateState({
        form: newFormJson.plainJson,
        originalForm,
        structure: newFormJson.structureJson,
        template: parsedTemplate,
        noRenderFields: tempNoRender,
        loadedData: true
      })
      setLoadForm(true)
    }
  }, [formStates])

  const findApplicationByUuidSuccess = (application: Application): void => {
    const tempForm = { ...form }
    if (application.data === undefined) return
    addValuesToTemporalForm(application, tempForm)
    toggleFormRefresh(tempForm)
    if (applicationCookie?.fromEdit ?? applicationCookie?.continueFromHome) {
      const lastStep = getLastStepBeforeResumingApplication(
        application.data,
        newStepStructure(form, structure, originalForm, [], true).newStepStructure
      )
      setRecoveredLastStep(lastStep)
    }
  }

  React.useEffect(() => {
    const fecthData = async (): Promise<void> => {
      if (!loadForm) return
      if (!applicationCookie?.id) {
        isLoaded(false)
        return
      }
      await findApplicationByUuidUseCase({
        applicationId: applicationCookie.id,
        successCallback: findApplicationByUuidSuccess,
        finallyCallback: () => {
          isLoaded(false)
        }
      })
    }
    fecthData()
  }, [loadedData, loadForm])

  React.useEffect(() => {
    if (tooltipModalContent === null) return
    if (!tooltipModal.modalOpen) {
      tooltipModal.changeModalState()
    }
  }, [tooltipModalContent])

  const resetCookies = (): void => {
    clearApplicationCookie()
  }

  const sectionList = getSectionList(stepsData)

  const sectionsActivated = formData.activateSections
  const { disabledNextStep, handleChange, toggleFormRefresh } = useActions({
    itemChangedKey,
    toggleGroupNames,
    formData,
    updateState,
    groupValidator,
    form,
    oldNoRenderFields,
    structure,
    state,
    resetCookies,
    applicationId: applicationCookie?.id,
    userCookie
  })
  const { updateStepAndStructure } = updateCurrentStepAndStructure(stepsData, updateState, formData)

  const customGoToStep = (goToStep): void => {
    setCustomGoToStep({ goToStep })
  }

  const closeOverlay = (): void => {
    setOverlayState(false)
  }

  const toggleNames = (): void => {
    updateState({ toggleGroupNames: !toggleGroupNames })
  }

  const toogleShowPreview = (): void => {
    updateShowPreviewSidebar(!showPreviewSidebar)
  }

  const toogleEnablePreviewScroll = (): void => {
    setDisabledPreviewScroll(!disabledPreviewScroll)
  }

  const RefreshButton = <Toggle toggleCheck={toggleGroupNames} toggleAction={toggleNames} label='Hide/Show' />
  const ShowPreviewToogle = (
    <Toggle
      key='show-preview-toogle'
      toggleCheck={showPreviewSidebar}
      toggleAction={toogleShowPreview}
      label='Hide/Show Preview'
    />
  )
  const buttonEnablePreviewScroll = (
    <Toggle
      toggleCheck={disabledPreviewScroll}
      toggleAction={toogleEnablePreviewScroll}
      label='Enable/Disable Preview scroll'
    />
  )

  const formType = productName?.toLowerCase().split(' ').join('-')

  const formStateName = formData.selectState ? formData.selectState.name?.toLowerCase().split(' ').join('-') : null

  const urlState: string = replaceAll(formData.name?.toLowerCase() ?? '', ' ', '-')
  const subtypeState: string = replaceAll(urlState, `-${formType}`, '')
  const formSubType: string | undefined = formData.subtype ? stringSlugify(formData.subtype.subtype) : subtypeState

  const toggleOpenRegisterCloseLogin = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  const toggleOpenLoginCloseRegister = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  // It can happen that the number of steps refreshes before currentStep, causing the latter to fall outside the array of steps.
  // We check this to avoid temporarily rendering the navbar in that case
  const currentStepSmallerThanAllSteps = stepsData.newStepStructure.steps ? state.currentStep <= stepsCount : false

  const classFormTemplate = classNames({
    'flex form__template__wrapper': true,
    column: true,
    '--last-step': isLastStep
  })

  const value = {
    customGoToStep,
    customGoToStepFunc: customGoToStepObject ? customGoToStepObject.goToStep : null,
    driveId: formData.driveId,
    form,
    formTypeId: formData.formType?.typeId,
    formName: formData.name,
    formStateName,
    formSubType,
    formType,
    formUrl: formData.url,
    fromEdit: applicationCookie?.fromEdit,
    handleChange,
    hasSubscriptionActive,
    isSevenDayAccess,
    isValidGroup: groupValidator,
    loadingSubscription,
    noRenderFields,
    originalForm,
    resetCookies,
    sectionsActivated,
    closeTooltipModal: tooltipModal.closeModal,
    setTooltipModalContent,
    state: formData.selectState ? formData.selectState.value : null,
    steps: formData.stepsForm,
    stepValidator,
    structure,
    subscription,
    setSubscription,
    subscriptionsType,
    template,
    toggleGroupNames,
    updateCurrentStepAndStructure: updateStepAndStructure,
    uri,
    userCookie,
    validation: fieldValidator
  }

  const MainComponent =
    !loading && loadForm ?
      // TODO que hacer con el ApplicationContext que está embebidio en el FormContext
      <FormContext.Provider value={value}>
        <OverlayContext.Provider value={showOverlay}>
          {registerModal.modalOpen && (
            <RegisterModal
              toggleOpenLoginCloseRegister={toggleOpenLoginCloseRegister}
              isRegisterModal
              closeFunction={registerModal.closeModal}
              onToggleModal={registerModal.changeModalState}
              loginModalOpen={loginModal.modalOpen}
              component={<TemplateContainer template={template} borderRadius={true} />}
              urlRedirect={registerModal.urlRedirect}
              changeUrlRedirect={registerModal.changeUrlRedirect}
            />
          )}
          {loginModal.modalOpen && (
            <LoginModal
              toggleOpenRegisterCloseLogin={toggleOpenRegisterCloseLogin}
              isLoginModal
              closeFunction={loginModal.closeModal}
              onToggleModal={loginModal.changeModalState}
              registerModalOpen={registerModal.modalOpen}
              component={<TemplateContainer template={template} borderRadius={true} />}
              isTestAB8814={true}
            />
          )}
          <TooltipModal
            modalOpen={tooltipModal.modalOpen}
            closeFunction={tooltipModal.closeModal}
            title={tooltipModalContent?.title}
            text={tooltipModalContent?.text}
          />
          <div id='form__template__wrapper' className={classFormTemplate}>
            {state.currentStructure.steps && currentStepSmallerThanAllSteps && (
              <ProgressBar
                customGoToStep={customGoToStepObject}
                allSteps={stepsData.newStepStructure.steps}
                currentStep={state.currentStep}
                sectionsActivated={sectionsActivated}
                sections={sectionList}
                formData={form}
                stepValidator={stepValidator}
                isValidGroup={groupValidator}
                onClose={closeOverlay}
                documentTitle={productName}
              />
            )}
            <div className={'form__template__wrapper__inner'}>
              <div className={'form__template__wrapper__inner__nopadding'}>
                {(IS_LOCAL_ENV || IS_STAGEPRODUCT_ENV) && (
                  <div className='control-panel'>
                    {RefreshButton}
                    {ShowPreviewToogle}
                    {buttonEnablePreviewScroll}
                  </div>
                )}
                {showPreviewSidebar && (
                  <div className='preview-sidebar'>
                    <TemplateContainer
                      template={template}
                      documentType={formType}
                      categoryProduct={formSubType}
                      borderRadius={true}
                      dinamicScroll={!disabledPreviewScroll}
                    />
                  </div>
                )}
                <div className={'form__template__isolate'}>
                  <div className={'form-container'}>
                    <MultiStepFormMessi
                      version={version}
                      formSubType={formSubType}
                      showPreviewLastStep={isLastStep}
                      isHashInUrlFirstTime={isHashInUrlFirstTime}
                      preFormDataToSendApplication={preFormDataToSendApplication}
                      disabledNextStep={disabledNextStep}
                      benefitsListToShow={ratafiaFreeBenefits}
                      recoveredLastStep={recoveredLastStep}
                      callbackBottomOverlay={callbackBottomOverlay}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {templateModal.modalOpen && (
            <Modal title={title} closeFunction={templateModal.closeModal}>
              <TemplateContainer template={template} borderRadius={true} />
            </Modal>
          )}
        </OverlayContext.Provider>
      </FormContext.Provider>
    : <Loading />

  return (
    <div className='formtemplateShakira'>
      <Layout
        headerFullWidth={true}
        headerBackground={'white'}
        headerHasNoProducts={true}
        noFooter={true}
        toggleRegister={registerModal.changeModalState}
        toggleLogin={loginModal.changeModalState}
        registerModalOpen={registerModal.modalOpen}
        loginModalOpen={loginModal.modalOpen}
        changeUrlRedirect={registerModal.changeUrlRedirect}
        footerContactBar={true}
        isTestAB8814={true}
        currentStepName={state?.currentStructure?.steps?.[state?.currentStep - 1]?.name}
        disclaimerLinks={true}
        environment={TARGET_ADDRESS}
        logoShowModal={true}
        helpLinksShowModal={true}
        mobileHideResources={true}
        bottomOverlayContent={bottomOverlayContent}
        showContactBarMobile
        desktopNavbarPhone
      >
        {MainComponent}
      </Layout>
    </div>
  )
}
