import { getValue } from './getValueFromForm'
import { logError } from '@legal/shared/logger'
import { replaceAll } from '../utils/replaceAll'

export interface FormatDataStrapiVariables {
  [key: string]: {
    id: string
    component: string
    type: string
    givenClass?: string
    placeholder: string
    label: string
    value: string
    format?: string
    mask?: string
    name: string
    tooltip: string
    errorMessage: string
    required: boolean
    valueType?: string
    validate: boolean
    isValidGroup: boolean
    disabled: boolean
    class?: string
    items?: FormatDataStrapiVariablesItem[]
  }
}

export interface FormatDataStrapiVariablesItem {
  value: string
  label: string
  checked: boolean
  iconName: string
}

export const formatDataStrapiVariables = (field: string, vars: FormatDataStrapiVariables): string => {
  const fieldNoJumpNoEspacesIF = clearJumpsAndSpaces(field)
  const resWithCurlyBrackets = getBracketsElements(fieldNoJumpNoEspacesIF)

  let resultTransformField: string = fieldNoJumpNoEspacesIF

  resWithCurlyBrackets?.forEach((result) => {
    const IFElements = getIFElements(result)

    if (IFElements) {
      resultTransformField = formatIf(IFElements, vars, resultTransformField)
    }
  })

  return resultTransformField
}

export const remplaceFieldsWithVariables = (field: string, vars: FormatDataStrapiVariables): string => {
  const dataClaspThree = getClaspThreeElements(field)

  dataClaspThree?.forEach((element) => {
    const result = getValue(element, vars)
    field = replaceAll(field, `[[[${element}]]]`, `${result}`) ?? field
  })

  const dataClasp = getClaspElements(field)

  dataClasp?.forEach((element) => {
    const result = getValue(element, vars)
    field = replaceAll(field, `[[${element}]]`, `"${result}"`) ?? field
  })

  return field
}

export const formatIf = (
  IFElements: RegExpMatchArray,
  vars: FormatDataStrapiVariables,
  resultTransformField: string
): string => {
  IFElements?.forEach((resultIF) => {
    const resultIFWithVariables = remplaceFieldsWithVariables(resultIF, vars)

    let findConditionTrue = false
    let resultIfFinal
    let itemResultIf

    const regexpIFCondition = /(?:@IF|@ELSE).*?\}/g
    let error = null
    while ((itemResultIf = regexpIFCondition.exec(resultIFWithVariables)) !== null && !findConditionTrue) {
      const data = itemResultIf?.[0]

      const resultIfItem = getResultValidateConditionIf(data)
      resultIfFinal = resultIfItem.result
      error = resultIfItem.error
      if (resultIfFinal || resultIfFinal === '' || error) findConditionTrue = true
    }

    if (resultIfFinal) {
      resultTransformField = resultTransformField.replace(resultIF, resultIfFinal)
    } else if (!error) {
      resultTransformField = resultTransformField.replace(resultIF, '')
    }
  })
  return resultTransformField
}

export const clearBracketsAndSpaces = (content: string): string => {
  const contentNoBrackets = content.replace(/{/g, '').replace(/}/g, '')
  const contentCleanSpaces = contentNoBrackets.trim()

  let resultFinal = contentCleanSpaces
  if (contentCleanSpaces.startsWith('"') || contentCleanSpaces.startsWith("'")) {
    resultFinal = resultFinal.slice(1, resultFinal.length - 1)
  }
  if (
    contentCleanSpaces.charAt(resultFinal.length - 1) === '"' ||
    contentCleanSpaces.charAt(resultFinal.length - 1) === "'"
  ) {
    resultFinal = resultFinal.slice(0, resultFinal.length - 2)
  }

  return resultFinal
}

export const clearJumpsAndSpaces = (content: string): string => {
  const fieldNoJump = content.replace(/[\r\n]+/g, '')
  const fieldRemoveSpacesJsIf = fieldNoJump.replace(/{{\s+/g, '{{').replace(/\s+@IF/g, ' @IF')
  const fieldRemoveSpacesJsEndIf = fieldRemoveSpacesJsIf.replace(/ENDIF\s+/g, 'ENDIF').replace(/\s+}}/g, '}}')
  return fieldRemoveSpacesJsEndIf
}

export const getClaspElements = (content: string): string[] | undefined => {
  const regexpWithCurlyClasp = /\[\[(.+?)\]\]/g
  const resWithCurlyClasp = content.match(regexpWithCurlyClasp)?.map((match) => match.replace(/\[\[|\]\]/g, ''))
  return resWithCurlyClasp
}

export const getClaspThreeElements = (content: string): string[] | undefined => {
  const regexpWithCurlyClasp = /\[\[\[(.+?)\]\]\]/g
  const resWithCurlyClasp = content.match(regexpWithCurlyClasp)?.map((match) => match.replace(/\[\[\[|\]\]\]/g, ''))
  return resWithCurlyClasp
}

export const getBracketsElements = (content: string): RegExpMatchArray | null => {
  const regexpWithCurlyBrackets = /\{{.*?\}}/g
  const resWithCurlyBrackets = content.match(regexpWithCurlyBrackets)
  return resWithCurlyBrackets
}

export const getIFElements = (content: string): RegExpMatchArray | null => {
  const regexpIF = /{{@IF.*?ENDIF}}/g
  const resWithIF = content.match(regexpIF)
  return resWithIF
}

export const getContentKeys = (content: string): string => {
  const regexpInterKeys = /{.*?}/g
  const dataInKeys = content.match(regexpInterKeys)
  const contentKeys = dataInKeys?.[0] ?? ''
  return contentKeys
}

export const getIfCondition = (content: string): string => {
  const lastParantesis = content.lastIndexOf('){')
  const resultIFConditionElement = content.slice(0, lastParantesis + 1)

  const resultIFConditionNoParenthesisInitial = resultIFConditionElement?.replace('@IF', '')
  return resultIFConditionNoParenthesisInitial ?? ''
}

export const getElseResult = (content: string): string => {
  const resultElseConditionNoKeyInitial = content.replace(/[@ELSE{]+/g, '')
  const resultElseConditionNoKeyFinal = resultElseConditionNoKeyInitial?.substring(
    0,
    resultElseConditionNoKeyInitial.length - 1
  )

  return resultElseConditionNoKeyFinal ?? ''
}

export const getResultValidateConditionIf = (data: string): { result: string | null; error: boolean } => {
  if (data.includes('@ELSE')) {
    const resultElse = getElseResult(data)
    const resultElseClear = clearBracketsAndSpaces(resultElse)
    return { result: resultElseClear, error: false }
  } else if (data) {
    const ifCondition = getIfCondition(data)
    const content = getContentKeys(data)
    const contentClear = clearBracketsAndSpaces(content)
    let resultCondition = null

    try {
      resultCondition = eval(ifCondition)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_error) {
      logError('condition ' + ifCondition)
      return { result: null, error: true }
    }

    if (resultCondition) {
      return { result: contentClear, error: false }
    }
  }
  return { result: null, error: false }
}
