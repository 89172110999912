import { clearJumpsAndSpaces, formatDataStrapiVariables } from './formatDataStrapiVariables'
import { getValueFromForm } from './getValueFromForm'
import { replaceAll } from '../../../src/services/utils/replaceAll'

const parseLabels = ({ labels, form }): unknown => {
  return labels.map((item) => {
    const label = parseLabel({ label: item.label, form })
    return { ...item, label }
  })
}
const parseLabel = ({ label, form }): string => {
  let labelClean = label
  if (label && typeof label === 'string' && label.includes('{{')) {
    labelClean = clearJumpsAndSpaces(label)
    const regexpWithCurlyBrackets = /\{{.*?\}}/g
    const resWithCurlyBrackets = labelClean.match(regexpWithCurlyBrackets)

    if (resWithCurlyBrackets) {
      resWithCurlyBrackets.forEach((result) => {
        if (result.includes('@IF')) {
          const resultDataIf = formatDataStrapiVariables(result, form)
          labelClean = replaceAll(labelClean, result, resultDataIf)
        } else {
          labelClean = getValueFromForm(result, form, labelClean)
        }
      })
    }
  }
  return labelClean || ''
}

export { parseLabel, parseLabels }
