import { graphql, useStaticQuery } from 'gatsby'

export const useQueryRatafiaPricingPage = (): Queries.STRAPI_LAW_GENIUS_PRICING_PAGE => {
  const { strapiLawGeniusPricingPage } = useStaticQuery(graphql`
    query {
      strapiLawGeniusPricingPage {
        title
        CardSubscription {
          id
          cardStyle
          iconTitle {
            name
            url
          }
          title
          priceText
          mainFeatures {
            description
            image {
              name
              url
            }
            title
          }
          AIFeatures {
            image {
              name
              url
            }
            description
            title
          }
          buttonText
          linkText
        }
      }
    }
  `)

  return strapiLawGeniusPricingPage as Queries.STRAPI_LAW_GENIUS_PRICING_PAGE
}
