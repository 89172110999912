/* eslint-disable react/jsx-key */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'
import StepWizard from 'react-step-wizard'
import classNames from 'classnames'

import {
  type Application,
  type CreateApplicationRequest,
  createApplicationUseCase,
  updateApplicationDataUseCase,
  updateApplicationFormUseCase
} from '@legal/application'
import { ContinueModalContent, ProductFaqs8814 } from '../../molecules'
import { FormContext } from '../../../services/utils/contexts'
import { MainFormStepsMessi } from '../MainFormStepsMessi'
import { type MultiStepFormMessiProps } from './MultiStepFormMessiProps'
import { UtmCookie } from '../../../services/storage/cookies/UtmCookie'
import { newStepStructure } from '../../../services/form/newStepStructure'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useModal } from '../../../services/hooks/useModal'
import { useQueryFormPage } from '@legal/shared/data/graphql'

enum versionBenefitsList {
  default = 'default'
}

const fadeInOut = {
  enterRight: 'animated fadeIn',
  enterLeft: 'animated fadeIn',
  exitRight: 'animated fadeOut',
  exitLeft: 'animated fadeOut'
}

export const MultiStepFormMessi: React.FC<MultiStepFormMessiProps> = ({
  benefitsListToShow,
  showPreviewLastStep,
  formSubType,
  isHashInUrlFirstTime,
  preFormDataToSendApplication,
  disabledNextStep,
  version,
  recoveredLastStep,
  callbackBottomOverlay
}) => {
  const {
    applicationCookie,
    replaceApplicationCookie,
    setApplicationCookieFormName,
    setApplicationCookieFormDriveId,
    setApplicationCookieFromEdit,
    setApplicationCookieContinueFromHome
  } = useApplicationCookie()
  const { multiStepFormWizard } = useQueryFormPage()
  const utmCookie = new UtmCookie()
  const { modalOpen, changeModalState, closeModal } = useModal()
  const formContext = React.useContext(FormContext)
  const newStepsStructure = newStepStructure(
    formContext.form,
    formContext.structure,
    formContext.originalForm,
    formContext.noRenderFields,
    true
  )
  const formSteps = newStepsStructure.newStepStructure ?? []
  const [sendDataPreForm, setSendDataPreForm] = React.useState<boolean>()
  const [changeRecoveredLastStep, setChangeRecoveredLastStep] = React.useState<boolean>(false)
  const [faqs, setFaqs] = React.useState(null)
  const [isLastStepActive, setIsLastStepActive] = React.useState(null)
  const [stepActiveIndex, setStepActiveIndex] = React.useState(1)
  const activeVersion = versionBenefitsList.default

  const responseSuccessCreateApplication = (application: Partial<Application>): void => {
    replaceApplicationCookie({
      ...application,
      form: { ...application.form, name: formContext.formName, subType: formContext.formSubType }
    })
  }

  const createApplication = React.useCallback(async (dataToSend: CreateApplicationRequest): void => {
    Object.entries(utmCookie._getCookie()).forEach((utm) => (dataToSend[utm[0]] = utm[1]))
    dataToSend.testAbIdentifier = '8814'
    formContext.resetCookies()
    await createApplicationUseCase({
      request: dataToSend,
      successCallback: (application) => {
        responseSuccessCreateApplication(application)
      }
    })
  }, [])

  const updateApplication = React.useCallback(async (): Promise<void> => {
    if (!applicationCookie?.id) return
    await updateApplicationFormUseCase({
      applicationId: applicationCookie.id,
      request: {
        driveId: applicationCookie.form?.driveId ?? formContext.driveId,
        stateCode: formContext.state,
        htmlPreview: ReactDOMServer.renderToStaticMarkup(formContext.template)
      },
      successCallback: () => {
        setApplicationCookieFormName(formContext.formName)
      }
    })
  }, [])

  const createApplicationEmpty = (): void => {
    const dataToSend: CreateApplicationRequest = {
      driveId: formContext.driveId,
      htmlPreview: ReactDOMServer.renderToStaticMarkup(formContext.template),
      gclid: utmCookie.gclid
    }
    if (formContext.state) {
      dataToSend.stateCode = formContext.state
    }
    createApplication(dataToSend)
  }

  const createApplicationModal = React.useCallback((): void => {
    formContext.resetCookies()
    changeModalState()
    location.reload()
  }, [])

  const resumeApplicationModal = (): void => {
    if (applicationCookie?.lastStep) {
      formContext.customGoToStepFunc(applicationCookie.lastStep + 1)
    }
    changeModalState()
  }

  React.useEffect(() => {
    const isLogged = formContext.userCookie.token
    const isDifferentForm = applicationCookie?.form?.driveId !== formContext.driveId
    if (!applicationCookie?.id) {
      createApplicationEmpty()
      return
    }
    if (isDifferentForm && !isLogged) {
      setApplicationCookieFormDriveId(formContext.driveId)
      updateApplication()
      return
    }
    if (applicationCookie.fromEdit ?? applicationCookie.continueFromHome) {
      setApplicationCookieFromEdit(false)
      setApplicationCookieContinueFromHome(false)
      return
    }
    if (!isLogged) return
    if (isDifferentForm) {
      createApplicationEmpty()
      return
    }

    changeModalState()
  }, [])

  React.useEffect(() => {
    const update = async (): Promise<void> => {
      if (applicationCookie?.id && preFormDataToSendApplication && !sendDataPreForm) {
        await updateApplicationDataUseCase({
          applicationId: applicationCookie.id,
          request: {
            data: preFormDataToSendApplication,
            step: formSteps.steps[0].slug,
            stepNumber: 1,
            html: ReactDOMServer.renderToStaticMarkup(formContext.template),
            testAbIdentifier: activeVersion
          },
          finallyCallback: () => {
            setSendDataPreForm(true)
          }
        })
      }
    }
    update()
  }, [preFormDataToSendApplication])

  const classFormAndFaqs = classNames({
    'form-faqs': true,
    '--last-step': isLastStepActive
  })

  if (!formSteps?.steps || formSteps.steps.length === 0) return <Spinner />

  return (
    <div className='flex'>
      <div className={classFormAndFaqs}>
        <div className='form-step-parent'>
          <React.Fragment key={JSON.stringify(formSteps.steps)}>
            {modalOpen && (
              <ContinueModalContent
                createApplicationModal={createApplicationModal}
                resumeApplicationModal={resumeApplicationModal}
                closeFunction={closeModal}
              />
            )}
            <StepWizard isHashEnabled={true} isLazyMount={true} transitions={fadeInOut} className={'flex'}>
              {formSteps.steps.map((step, index) => {
                if (step) {
                  return (
                    <MainFormStepsMessi
                      // testAB8814
                      version={version}
                      showPreviewLastStep={showPreviewLastStep}
                      key={step?.label ?? ''}
                      structure={formSteps}
                      modalOpen={modalOpen}
                      closeModal={closeModal}
                      createApplicationModal={createApplicationModal}
                      hashKey={step.slug}
                      customGoToStep={formContext.customGoToStep}
                      updateApplication={updateApplication}
                      createApplication={createApplication}
                      template={formContext.template}
                      formType={formContext.formType}
                      formSubType={formSubType}
                      isHashInUrlFirstTime={isHashInUrlFirstTime}
                      applicationId={applicationCookie?.id}
                      faqs={faqs}
                      setFaqs={setFaqs}
                      setIsLastStepActive={setIsLastStepActive}
                      handleStepActive={setStepActiveIndex}
                      indexItem={index}
                      formStateName={formContext.formStateName}
                      disabledNextStep={disabledNextStep}
                      recoveredLastStep={recoveredLastStep}
                      changeRecoveredLastStep={changeRecoveredLastStep}
                      handleChangeRecoveredLastStep={setChangeRecoveredLastStep}
                      callbackBottomOverlay={callbackBottomOverlay}
                      activeTestABVersion={activeVersion}
                      benefitsListToShow={benefitsListToShow}
                    />
                  )
                } else {
                  return (
                    <div>
                      <h1>{multiStepFormWizard?.noStepsDefined}</h1>
                      <h3>{multiStepFormWizard?.someErrorWith}</h3>
                    </div>
                  )
                }
              })}
            </StepWizard>
          </React.Fragment>
        </div>
        {faqs && faqs.length > 0 && (
          <ProductFaqs8814
            faqs={faqs}
            formContext={formContext}
            stepActive={formSteps.steps[stepActiveIndex]}
            formType={formContext.formType}
            formSubType={formSubType}
          />
        )}
      </div>
    </div>
  )
}
