/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React, { useEffect, useState } from 'react'
import sanitizeHtml from 'sanitize-html'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { type Subscription, getSubscriptionTypeByCodeUseCase, getSubscriptionUseCase } from '@legal/subscription'
import { type PaymentSummaryProps } from './PaymentSummaryProps'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { SubscriptionTypeName } from '@legal/shared/data'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import bitmap1 from '../../../assets/images/svg/Bitmap-1.svg'
import bitmap2 from '../../../assets/images/svg/Bitmap-2.svg'
import comodosecure from '../../../assets/images/png/comodo-secure.png'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useBenefitsListToShow } from '@legal/shared/hooks/useBenefitsListToshow'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'
import { useSubscriptionDataStrapi } from '@legal/shared/hooks/useSubscriptionDataStrapi'

import CheckIcon from '../../../assets/images/componentsSvg/check.svg'
import RatafiaIcon from '../../../assets/images/componentsSvg/ratafia-24px.svg'
import ssl from '../../../assets/images/svg/SSL_certificate.svg'

const DISPLAY_PRICE = '{{DISPLAY_PRICE}}'

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  currencySymbol,
  subscriptionInformation,
  paymentSummaryData
}) => {
  const { currency } = useQuerySharedComponentsFromPaymentPage()

  const { applicationCookie } = useApplicationCookie()
  const [currentSubscription, setCurrentSubscription] = useState<Subscription>()
  const [currentSubscriptionInitialAmount, setCurrentSubscriptionInitialAmount] = useState<string>()

  const userCookie = new UserCookie()
  const subscriptionCookie = new SubscriptionCookie()

  const userToken = userCookie.token ?? ''

  const benefitBuilder: { [key: string]: { classname: string; icon: JSX.Element } } = {
    default: {
      classname: 'default',
      icon: <CheckIcon className='m-paymentForm__benefits__list__item__icon small-text sans-serif --small' />
    },
    lawgenius: {
      classname: 'lawgenius',
      icon: <RatafiaIcon className='m-paymentForm__benefits__list__item__icon small-text sans-serif --small' />
    }
  }

  useEffect(() => {
    if (!subscriptionCookie) {
      window.location = '/pricing/'
    }
  }, [])

  useEffect(() => {
    if (subscriptionInformation) {
      getSubscriptionInformation(userToken)
    }
  }, [subscriptionInformation])

  useEffect(() => {
    if (currentSubscription) {
      const initialDocumentType = applicationCookie?.form?.name
      const eventProps = {
        initial_document_type: stringSlugify(initialDocumentType) ?? null,
        change_price_allowed: currentSubscription.type.name !== SubscriptionTypeName.BEST_VALUE,
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      }
      sendAmplitudeData('show_payment_page', eventProps)
    }
  }, [currentSubscription])

  const getSubscriptionInformation = async (userToken?: string): Promise<void> => {
    const subscriptionPriceId = subscriptionCookie.subscriptionId ?? window.history.state?.subscriptionId
    if (!userToken) {
      await getSubscriptionTypeByCodeUseCase({
        successCallback: (subscriptions) => {
          const subscriptionFound = subscriptions.find(({ id }) => id === subscriptionCookie.subscriptionTypeId)
          setCurrentSubscription(subscriptionFound)
          setCurrentSubscriptionInitialAmount(subscriptionFound?.initialAmountFormated)
        }
      })
      return
    }
    await getSubscriptionUseCase({
      request: { subscriptionId: subscriptionPriceId },
      successCallback: (response) => {
        if (response.id === subscriptionCookie.subscriptionId) {
          setCurrentSubscription(response)
          setCurrentSubscriptionInitialAmount(response.price?.initialAmount)
        }
      }
    })
  }

  const { benefitsListToShow } = useBenefitsListToShow({ subscription: currentSubscription })
  const { subscriptionData } = useSubscriptionDataStrapi({
    nameSubscription: currentSubscription?.type?.name ?? ''
  })

  return (
    <div className='right-container'>
      {currentSubscription && (
        <div className='m-paymentForm-container inner-container top'>
          <div>
            <div className='right-container__title'>
              <h3 className='bold sans-serif --big'>{paymentSummaryData?.title}</h3>
            </div>
            <hr />
            <>
              <p>{subscriptionData?.name}</p>
              {currentSubscriptionInitialAmount && (
                <h2 className='price'>
                  {currencySymbol}
                  {currentSubscriptionInitialAmount}
                  <span className='currency currency-small'>{currency}</span>
                </h2>
              )}
              <ul className='m-paymentForm__benefits__list'>
                {benefitsListToShow?.map((item) => {
                  const currentType = benefitBuilder[item.type] ?? benefitBuilder['default']
                  return (
                    <li key={item.benefit} className={`m-paymentForm__benefits__list__item ${currentType.classname}`}>
                      {currentType.icon}
                      <small
                        className='m-paymentForm__benefits__list__item__text sans-serif --small'
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            item.benefit.includes(DISPLAY_PRICE) ? paymentSummaryData?.cancelAnyTime : item.benefit
                          )
                        }}
                      />
                    </li>
                  )
                })}
              </ul>
              <br />
            </>
          </div>
          <br />
          <h3 className='sans-serif --big'>{paymentSummaryData?.orderSummary}</h3>
          <hr className='bottom-hr' />
          <p>
            {paymentSummaryData?.totalAmount}{' '}
            <span className='bold'>{`${currencySymbol}${currentSubscriptionInitialAmount}`}</span>
            <span className='currency'>
              {currentSubscriptionInitialAmount && `${currency} ${subscriptionData?.timeDuration ?? ''}`}
            </span>
          </p>
        </div>
      )}
      <div className='m-paymentForm-container inner-container bottom'>
        <p>{paymentSummaryData?.payWithConfidence}</p>
        <p>{paymentSummaryData?.thisSiteIsSSLSecured}</p>
        <div className='m-paymentForm-images'>
          <img alt='' src={bitmap1} />
          <img alt='' src={comodosecure} />
          <img alt='' src={bitmap2} />
          <img alt='' src={ssl} />
        </div>
      </div>
    </div>
  )
}
