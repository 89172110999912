import React, { type FC } from 'react'
import { BottomFixedBar } from '@npm_leadtech/legal-lib-components/BottomFixedBar'
import { RichTextStrapi } from '@npm_leadtech/legal-lib-components/RichTextStrapi'

import { type CookiePolicyBarProps } from './CookiePolicyBarProps'
import { useQueryCookiePolicyBar } from '@legal/shared/data/graphql'
import { useRichTextSubstitution } from '@legal/shared/hooks'
import './CookiePolicyBar.scss'

export const CookiePolicyBar: FC<CookiePolicyBarProps> = ({ clickFunc }) => {
  const cookiePolicyBar = useQueryCookiePolicyBar()
  const { replacedText } = useRichTextSubstitution(cookiePolicyBar.text?.data?.childMarkdownRemark?.html)

  return (
    <BottomFixedBar
      text={
        <RichTextStrapi html={replacedText ?? undefined} className='cookie-policy-bar__text sans-serif --extra-small' />
      }
      button={{
        label: cookiePolicyBar.buttonText ?? '',
        onClick: clickFunc
      }}
    />
  )
}
