import { Form } from '../../domain'
import { FormData } from '../dataObjects/FormData'

export function FormDataToForm(data: FormData): Form {
  return {
    slug: data.slugUrl,
    states: data.states.map((state) => ({
      name: state.name,
      code: state.code
    }))
  }
}
