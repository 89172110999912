/* eslint-disable react/jsx-key */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'
import StepWizard from 'react-step-wizard'
import classNames from 'classnames'

import {
  type Application,
  type CreateApplicationRequest,
  createApplicationUseCase,
  updateApplicationDataUseCase,
  updateApplicationFormUseCase
} from '@legal/application'
import { ContinueModalContent, ProductFaqs8814 } from '../../molecules'
import { FormContext } from '../../../services/utils/contexts'
import { MainFormSteps } from '../MainFormSteps'
import { type MultiStepFormProps } from './MultiStepFormProps'
import { UtmCookie } from '../../../services/storage/cookies/UtmCookie'
import { newStepStructure } from '../../../services/form/newStepStructure'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useModal } from '../../../services/hooks/useModal'
import { useQueryFormPage } from '@legal/shared/data/graphql'

export const MultiStepForm: React.FC<MultiStepFormProps> = ({
  showPreviewLastStep,
  form,
  formType,
  formSubType,
  isTestAB8814 = false,
  isHashInUrlFirstTime,
  preFormDataToSendApplication,
  disabledNextStep = false,
  recoveredLastStep
}) => {
  const { applicationCookie, replaceApplicationCookie, setApplicationCookieFormName, setApplicationCookieFormDriveId } =
    useApplicationCookie()
  const utmCookie = new UtmCookie()
  const { multiStepFormWizard } = useQueryFormPage()
  const { modalOpen, changeModalState, closeModal } = useModal()
  const formContext = React.useContext(FormContext)
  const newStepsStructure = newStepStructure(
    form,
    formContext.structure,
    formContext.originalForm,
    formContext.noRenderFields
  )
  const formSteps = newStepsStructure.newStepStructure ? newStepsStructure.newStepStructure : []
  const [applicationId, setApplicationId] = React.useState<string>()
  const [sendDataPreForm, setSendDataPreForm] = React.useState<boolean>()
  const [changeRecoveredLastStep, setChangeRecoveredLastStep] = React.useState<boolean>(false)
  const [faqs, setFaqs] = React.useState(null)
  const [isLastStepActive, setIsLastStepActive] = React.useState<boolean | null>(null)
  const [stepActiveIndex, setStepActiveIndex] = React.useState(1)

  const responseSuccessCreateApplication = (application: Partial<Application>): void => {
    replaceApplicationCookie({
      ...application,
      form: {
        ...application.form,
        name: formContext.formName,
        subType: formContext.formSubType
      }
    })
    setApplicationId(application.id)
  }

  const createApplication = async (dataToSend: CreateApplicationRequest): void => {
    Object.entries(utmCookie._getCookie()).forEach((utm) => (dataToSend[utm[0]] = utm[1]))
    formContext.resetCookies()
    await createApplicationUseCase({
      request: dataToSend,
      successCallback: (application) => {
        responseSuccessCreateApplication(application)
      }
    })
  }

  const createApplicationEmpty = (): void => {
    const dataToSend: CreateApplicationRequest = {
      driveId: formContext.driveId,
      htmlPreview: ReactDOMServer.renderToStaticMarkup(formContext.template),
      gclid: utmCookie.gclid
    }
    if (formContext.state) {
      dataToSend.stateCode = formContext.state
    }
    createApplication(dataToSend)
  }

  const createApplicationModal = (): void => {
    formContext.resetCookies()
    changeModalState()
    location.reload()
  }

  const resumeApplicationModal = (): void => {
    if (applicationCookie?.lastStep) {
      formContext.customGoToStepFunc(applicationCookie.lastStep + 1)
    }
    changeModalState()
  }

  const updateApplication = async (): Promise<void> => {
    if (!applicationCookie?.id) return
    await updateApplicationFormUseCase({
      applicationId: applicationCookie.id,
      request: {
        driveId: applicationCookie.form?.driveId,
        stateCode: formContext.state,
        htmlPreview: ReactDOMServer.renderToStaticMarkup(formContext.template)
      },
      successCallback: () => {
        setApplicationCookieFormName(formContext.formName)
      }
    })
  }

  React.useEffect(() => {
    const isLogged = formContext.userCookie.token
    const isDifferentForm = applicationCookie?.form?.driveId !== formContext.driveId
    if (!applicationCookie?.id) {
      createApplicationEmpty()
    } else {
      if (isDifferentForm && !isLogged) {
        setApplicationCookieFormDriveId(formContext.driveId ?? '')
        updateApplication()
      } else if (!formContext.fromEdit && !applicationCookie.continueFromHome && isLogged) {
        if (isDifferentForm) {
          createApplicationEmpty()
        } else if (!isDifferentForm) {
          changeModalState()
        }
      }
    }
  }, [])

  React.useEffect(() => {
    const update = async (): Promise<void> => {
      if (applicationId && preFormDataToSendApplication && !sendDataPreForm) {
        if (!applicationCookie?.id) return
        await updateApplicationDataUseCase({
          applicationId: applicationCookie.id,
          request: {
            data: preFormDataToSendApplication,
            step: formSteps.steps[0].slug,
            stepNumber: 1,
            html: ReactDOMServer.renderToStaticMarkup(formContext.template)
          },
          finallyCallback: () => {
            setSendDataPreForm(true)
          }
        })
      }
    }
    update()
  }, [applicationId, preFormDataToSendApplication])

  const fadeInOut = {
    enterRight: 'animated fadeIn',
    enterLeft: 'animated fadeIn',
    exitRight: 'animated fadeOut',
    exitLeft: 'animated fadeOut'
  }

  const stepsToRender =
    formSteps.steps && formSteps.steps.length > 0 ?
      <React.Fragment key={JSON.stringify(formSteps.steps)}>
        {modalOpen && (
          <ContinueModalContent
            createApplicationModal={createApplicationModal}
            resumeApplicationModal={resumeApplicationModal}
            applicationId={applicationCookie?.id}
            closeFunction={closeModal}
          />
        )}
        <StepWizard isHashEnabled={true} isLazyMount={true} transitions={fadeInOut} className={'flex'}>
          {formSteps.steps.map((step) => {
            if (step) {
              return (
                <MainFormSteps
                  showPreviewLastStep={showPreviewLastStep}
                  key={step ? step.label : ''}
                  structure={formSteps}
                  modalOpen={modalOpen}
                  closeModal={closeModal}
                  createApplicationModal={createApplicationModal}
                  hashKey={step.slug}
                  customGoToStep={formContext.customGoToStep}
                  updateApplication={updateApplication}
                  createApplication={createApplication}
                  template={formContext.template}
                  formType={formType}
                  formSubType={formSubType}
                  isHashInUrlFirstTime={isHashInUrlFirstTime}
                  applicationId={applicationId}
                  disabledNextStep={disabledNextStep}
                  isTestAB8814={isTestAB8814}
                  recoveredLastStep={recoveredLastStep}
                  changeRecoveredLastStep={changeRecoveredLastStep}
                  handleChangeRecoveredLastStep={setChangeRecoveredLastStep}
                  form={form}
                />
              )
            } else {
              return (
                <div>
                  <h1>{multiStepFormWizard?.noStepsDefined}</h1>
                  <h3>{multiStepFormWizard?.someErrorWith}</h3>
                </div>
              )
            }
          })}
        </StepWizard>
      </React.Fragment>
    : <Spinner />

  const classFormAndFaqs = classNames({
    'form-faqs': true,
    '--last-step': isLastStepActive
  })

  const stepsToRenderIsTestAB =
    formSteps.steps && formSteps.steps.length > 0 ?
      <div className='flex'>
        <div className={classFormAndFaqs}>
          <div className='form-step-parent'>
            <React.Fragment key={JSON.stringify(formSteps.steps)}>
              {modalOpen && (
                <ContinueModalContent
                  createApplicationModal={createApplicationModal}
                  resumeApplicationModal={resumeApplicationModal}
                  closeFunction={closeModal}
                />
              )}
              <StepWizard isHashEnabled={true} isLazyMount={true} transitions={fadeInOut} className={'flex'}>
                {formSteps.steps.map((step, index) => {
                  if (step) {
                    return (
                      <MainFormSteps
                        showPreviewLastStep={showPreviewLastStep}
                        key={step ? step.label : ''}
                        structure={formSteps}
                        modalOpen={modalOpen}
                        closeModal={closeModal}
                        createApplicationModal={createApplicationModal}
                        hashKey={step.slug}
                        customGoToStep={formContext.customGoToStep}
                        updateApplication={updateApplication}
                        createApplication={createApplication}
                        template={formContext.template}
                        formType={formType}
                        formSubType={formSubType}
                        isHashInUrlFirstTime={isHashInUrlFirstTime}
                        applicationId={applicationId}
                        faqs={faqs}
                        setFaqs={setFaqs}
                        setIsLastStepActive={setIsLastStepActive}
                        handleStepActive={setStepActiveIndex}
                        indexItem={index}
                        formStateName={formContext.formStateName}
                        isTestAB8814={isTestAB8814}
                        recoveredLastStep={recoveredLastStep}
                        changeRecoveredLastStep={changeRecoveredLastStep}
                        handleChangeRecoveredLastStep={setChangeRecoveredLastStep}
                        form={form}
                      />
                    )
                  } else {
                    return (
                      <div>
                        <h1>{multiStepFormWizard?.noStepsDefined}</h1>
                        <h3>{multiStepFormWizard?.someErrorWith}</h3>
                      </div>
                    )
                  }
                })}
              </StepWizard>
            </React.Fragment>
          </div>
          {faqs && faqs.length > 0 && (
            <ProductFaqs8814
              faqs={faqs}
              formContext={formContext}
              stepActive={formSteps.steps[stepActiveIndex]}
              formType={formType}
              formSubType={formSubType}
            />
          )}
        </div>
      </div>
    : <Spinner />

  return (
    <>
      {!isTestAB8814 && stepsToRender}
      {isTestAB8814 && stepsToRenderIsTestAB}
    </>
  )
}
