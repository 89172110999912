/* eslint-disable @typescript-eslint/no-explicit-any */
import { getValueDatepicker } from './getValueDatepicker'

export const getValueFromForm = (keyedValue, form, label: string): any => {
  const formKey = keyedValue.substr(2).slice(0, -2)
  if (formKey.includes('||')) {
    const formKeyArray = formKey.split('||')
    if (formKey && form[formKeyArray[0]]) {
      let cleanValue = getValue(formKeyArray[0], form)
      if (!cleanValue) {
        cleanValue = formKeyArray[1]
      }
      return label.replace(keyedValue, cleanValue)
    }
  } else {
    return keyedValue
  }
}

export const getValue = (value: string, form: any): any => {
  if (form[value]?.component === 'DatePicker') {
    return getValueDatepicker(form[value])
  } else if (form[value]) {
    if (form[value]?.value?.value) {
      return form[value]?.value?.value
    } else {
      return form[value]?.value ? form[value].value : ''
    }
  }
  return ''
}
