import { ENDPOINTS } from './Endpoints'
import { Form } from '../../domain'
import { FormData } from '../dataObjects/FormData'
import { FormDataToForm } from '../adapters/FormDatatoForm'
import { get } from '@legal/shared/HttpClient'

export async function getFormsByType(type: string, country: string): Promise<Form[]> {
  const result = await get<FormData[]>(`${ENDPOINTS.FORMS}?type=${type}&country=${country}`)
  return result.map(FormDataToForm)
}
