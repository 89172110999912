import { Form, FormState } from '../'

export function getFormStates(forms: Form[]): FormState[] {
  const formsWithStates = forms?.filter((form) => form?.states?.length > 0)
  if (formsWithStates?.length == 0) return []
  return formsWithStates.map((form) => {
    return {
      label: form.states[0].name,
      value: form.states[0].code,
      slug: form.slug
    }
  })
}
