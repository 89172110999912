import { logError, logInfo } from '@legal/shared/logger'
import { Form } from '../domain'
import { FormRepositoryApi } from '../infra'
import { GetFormsByTypeRequest } from './interfaces/GetFormsByTypeRequest'

export async function getFormsByTypeUseCase({
  request,
  successCallback,
  errorCallback,
  finallyCallback
}: {
  request: GetFormsByTypeRequest
  successCallback: (forms: Form[]) => void
  errorCallback?: () => void
  finallyCallback?: () => void
}): Promise<void> {
  await FormRepositoryApi.getByType(request.type, request.country)
    .then((forms: Form[]) => {
      logInfo('getFormsByTypeUseCase success', request)
      successCallback(forms)
    })
    .catch((error: unknown) => {
      logError(`getFormsByTypeUseCase error`, undefined, error as Error)
      errorCallback?.()
    })
    .finally(() => {
      finallyCallback?.()
    })
}
